.modal_container {
    display: flex;
    flex-direction: column;
    width: 24%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 12px;
    padding: 24px;
    transition: width 2s;
    transition-timing-function: ease-out;

    &_header {
        display: flex;
    }
    &_heading {
        margin: 8px 0 22px 16px;
    }
    &_delete_icon {
        width: 30%;
    }
    &_footer {
        display: flex;
        align-self: flex-end;
    }
}

.yes_button {
    border-color: #F38B44 !important;
    color: #F38B44 !important;
    text-transform: capitalize !important;
    width: 90px;
}

.no_button {
    background-color: #F38B44 !important;
    color: white !important;
    margin-left: 16px !important;
    text-transform: capitalize !important;
    width: 90px;
}

.bold {
    font-weight: 600;
}