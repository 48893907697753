/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #09d3ac;
}

.wrapper{
  background:red;
  overflow:auto
}

.c2{
  float: right;
  background: green
}
​
.c1{
 float: left;
 background: blue
}

.main {
  width: 100%;
  height: 100vh;
  border: 1px solidgrey;
  display: -webkit-flex; /* Safari */
  display: block;
}
