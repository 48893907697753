$WHITE_SMOKE: #f4f4f4;
$ERROR_RED: #f66a6a;
$WHITE: #ffffff;
$ORANGE: #f38b44;
$GREY_DISABLED: #808080;
$DARK_GREY: #3d3d3d;
$GREY: #908f8f;
$GREEN: #149982;
$DARK_GREEN: #47b29f;
$GRAY_SHADOW: #e5e5e5;
$DARK_YELLOW_SHADE: #ffe6b1;
$DARK_RED: #ff3333;
$BLACK: #172a3a;
$LIGHT_GREY: #f0f0f0;

$BLUE_1: #0033cc;
$EXTRA_BLUE: #c9d5f8;
$STATUS_GREEN_1: #0d7e51;
$LIGHT_BLUE_2: rgba(155, 208, 250, 0.32);
$BLUE_2: #e5eaf9;
$STATUS_RED_1: #bb2e2e;
$STATUS_YELLOW_BG: #FFF1D9;
$GREY_1: #808080;
$STATUS_YELLOW_1: #FFA300;
$STATUS_GREEN_3: #DBECE5;
$GREY_2: #CCCCCC;
$SILVER : #F4F4F4;
$EXTRA_BLUE : #C9D5F8;
$RED1: #FF3333;
$BLUE_BG: #F2F5FC;

%blue-button {
    // align-items: center;
    padding: 9px 24px;
    background-color: $BLUE_1;
    border-radius: 8px;
    color: $WHITE;
    border: none;
    font-family: Manrope-Medium;
    font-weight: 400;
    outline: none;
    cursor: pointer;
    &:active {
    //   color: rgb(108, 69, 199);
      opacity: 0.7;
    }
    &:hover {
      border: none;
    }
}



