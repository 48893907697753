::-webkit-file-upload-button {
    color: #f38b44;
    /* padding: 1em; */
    border-radius: 6px;
    background-color: #ffffff;
    /* background-color: #f38b44; */
    box-shadow: none;
    width: 90px;
    height: 30px;
    border: none;
    outline:none;
    margin-top: 20px;
    font-size: 16px;
    font-family: 'Rubik';
}

