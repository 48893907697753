body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.rubikSmall {
  font-family: 'Rubik' !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  text-transform: none;
}

.rubikRegular {
  font-family: 'Rubik' !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-transform: none;
}

.rubikBold {
  font-family: 'Rubik' !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: none;
}

.internationalOrange {
  color: #BB2E2E !important;
}

@font-face {
  font-family: 'Manrope-Bold';
  src: local('Manrope-Bold'), url(./fonts/Manrope-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Manrope-ExtraBold';
  src: local('Manrope-ExtraBold'), url(./fonts/Manrope-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Manrope-ExtraLight';
  src: local('Manrope-ExtraLight'), url(./fonts/Manrope-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Manrope-Light';
  src: local('Manrope-Light'), url(./fonts/Manrope-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Manrope-Medium';
  src: local('Manrope-Medium'), url(./fonts/Manrope-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Manrope-Regular';
  src: local('Manrope-Regular'), url(./fonts/Manrope-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Manrope-SemiBold';
  src: local('Manrope-SemiBold'), url(./fonts/Manrope-SemiBold.ttf) format('truetype');
}


/* CUSTOM SCROLLBAR AS PER DESIGN */
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #9D9D9D;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #9D9D9D;
  border-radius: 20px;
  border: none;
}
