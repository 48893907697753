.invoiceBoxContainer {
    margin-top: 2vh;
    border-bottom: 1px solid #E5E5E5
}
.invoiceBoxItem {
    width: 100%;
    padding: 0 24px;
}
.invoiceDetailsRow {
    display: flex;
    width: calc(100% - 2vw);
    align-items: center;
    line-height: 24px;
    padding: 0px 1vw;
    border-radius: 4px;
    &_label {
        color: #908F8F;
        width: 50%;
        text-align: left;
    }
    &_value {
        color: #172A3A;
        text-align: left;
    }
    &:nth-child(even) {
        background: #f4f4f4;
    }
}
.invoicePaymentDetailContainer {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
    &_status {
        text-transform: uppercase !important;
        align-self: center;
        text-align: left;
        width: 50%;
        border-radius: 4px;
        padding: 4px 6px;
        width: 45px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 0.5vh;
        margin-bottom: 0.5vh;
    }
    &_button {
        color: #908F8F;
    }
}

.viewDetailButton {
    padding: 0 !important;
    &:hover {
        background-color: white !important;
    }
}

.statusColor {    
    &_PAID {
        color: green;
        background: #DBECE5;   
    }
    &_UNPAID {
        color: red;
        background: #F3D9D9;
    }
    &_PENDING_ON_NACH {
        color: purple;
    }
    &_DEFAULT {
        color: black;
    }
}

.paymentMethods {
    display: flex;
    flex-direction: column;
}

.modal_container {
    display: flex;
    flex-direction: column;
    width: 40%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 12px;
    padding: 24px;
    transition: width 2s;
    transition-timing-function: ease-out;

    &_footer {
        display: flex;
        align-self: flex-end;
    }
}


.methodUPI {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
}

.upiId {
    background: #FFF1E8;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 6px; 
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: right;
    width: 124px;
    margin-left: 6px;
}

.copyIcon {
    margin-left: 6px;
    &:hover {
        cursor: pointer;
    }
}

.buttons {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
    margin-bottom: 6px;
}

.buttonsStyle {
    display: flex;
    flex-direction: row;
    width: 91px;
    height: 23px;
}

.svgIcon {
    margin-right: 5px;
}

.divider {
        border: 1px solid #CCCCCC;
        width: 100%;
}

a {
    text-decoration: none;
}
